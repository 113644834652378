import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../axiosConfig';
import OperatorBeginningScreen from './OperatorBeginningScreen';

const OperatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #f0f2f5;
`;

const Operator = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    axios.get('/api/locations')
      .then(response => {
        setLocations(response.data);
        if (response.data.length > 0) {
          setSelectedLocation(response.data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching locations', error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        }
      });
  }, []);

  return (
    <OperatorContainer>
      {selectedLocation && (
        <OperatorBeginningScreen locationId={selectedLocation.id} />
      )}
    </OperatorContainer>
  );
};

export default Operator;