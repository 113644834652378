import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from '../axiosConfig';
import AddPallet from './AddPallet';
import FinishMenu from './FinishMenu';
import Settings from './Settings';
import Map from './Map';
import { startOfDay, startOfWeek, startOfMonth, format } from 'date-fns';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ScrollableTopMenu = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: #f0f0f0;
  padding: 10px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuButton = styled.button`
  background-color: ${props => props.active ? props.theme.colors.primary : 'white'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  opacity: ${props => props.faded ? 0.5 : 1};
  font-size: 1em;
  border-radius: 5px;
  &:hover {
    background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.secondary};
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;

export const ToggleButton = styled(MenuButton)`
  margin-bottom: 20px;
`;

export const Grid = styled.div`
  display: grid;
  gap: 15px;
  width: 100%;
`;

export const TopGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
`;

export const MiddleGrid = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
`;

export const BottomGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
`;

export const SelectionButton = styled.button`
  background-color: ${props => props.selected ? props.theme.colors.primary : 'white'};
  color: ${props => props.selected ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonLabel = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const ButtonInfo = styled.span`
  font-size: 12px;
`;

export const Divider = styled.hr`
  margin: 20px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #eee;
`;

export const Typography = styled.h2`
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
`;

export const SupplySystemToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const SupplySystemButton = styled(MenuButton)`
  margin: 0 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
`;

const StatisticsContainer = styled.div`
  padding: 20px;
`;

const StatHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StatTitle = styled.h2`
  margin-right: 10px;
`;

const ChangeButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const StatCard = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
`;

const OperatorBeginningScreen = ({ locationId, userId }) => {
  const [selectedPallets, setSelectedPallets] = useState({});
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [tomatoType, setTomatoType] = useState('Round');
  const [activeMenu, setActiveMenu] = useState('selection');
  const [recentlyFinished, setRecentlyFinished] = useState({});
  const [timeFrame, setTimeFrame] = useState('today');
  const [stats, setStats] = useState([]);
  const menuRef = useRef(null);
  const [filterRanges, setFilterRanges] = useState({
    light: { min: 0, max: 0 },
    middle: { min: 0, max: 0 },
    red: { min: 0, max: 0 }
  });

  useEffect(() => {
    // Load saved selections from localStorage when component mounts
    fetchFilterRanges();
    const savedSelections = localStorage.getItem(`palletSelections_${userId}`);
    if (savedSelections) {
      setSelectedPallets(JSON.parse(savedSelections));
    }

    // Load recently finished pallets from localStorage
    const storedRecentlyFinished = JSON.parse(localStorage.getItem('recentlyFinishedPallets')) || {};
    setRecentlyFinished(storedRecentlyFinished);

    // Cleanup interval for recently finished pallets
    const interval = setInterval(() => {
      const now = Date.now();
      setRecentlyFinished(prev => {
        const updated = { ...prev };
        let hasChanges = false;
        Object.keys(updated).forEach(key => {
          if (now - updated[key] > 600000) { // 10 minutes
            delete updated[key];
            hasChanges = true;
          }
        });
        if (hasChanges) {
          localStorage.setItem('recentlyFinishedPallets', JSON.stringify(updated));
        }
        return updated;
      });
    }, 60000);

    return () => clearInterval(interval);
  }, [userId]);

  const fetchFilterRanges = () => {
    axios.get(`/api/settings/${locationId}`)
      .then(response => {
        setFilterRanges(response.data.filterRanges);
      })
      .catch(error => {
        console.error('Error fetching filter ranges:', error);
      });
  };

  useEffect(() => {
    // Save selections to localStorage whenever it changes
    localStorage.setItem(`palletSelections_${userId}`, JSON.stringify(selectedPallets));
  }, [selectedPallets, userId]);

  const toggleTomatoType = () => {
    const newType = tomatoType === 'Round' ? 'Plum' : 'Round';
    if (window.confirm(`Are you sure you want to switch to ${newType}? This will deselect all current ${tomatoType} selections.`)) {
      setTomatoType(newType);
      setSelectedPallets(prev => {
        const newSelections = {};
        Object.entries(prev).forEach(([key, value]) => {
          if (value.tomatoType === newType) {
            newSelections[key] = value;
          }
        });
        return newSelections;
      });
    }
  };

  const handlePalletSelect = (position) => {
    setCurrentPosition(position);
    setShowAddPallet(true);
  };

  const handlePalletAdded = (palletData) => {
    setSelectedPallets(prev => ({
      ...prev,
      [currentPosition]: { ...palletData, tomatoType }
    }));
    setShowAddPallet(false);
  };

  const handleFinishPallet = (position, success) => {
    if (success) {
      console.log(`Pallet at position ${position} finished successfully`);
      const now = Date.now();
      setRecentlyFinished(prev => {
        const updated = { ...prev, [position]: now };
        localStorage.setItem('recentlyFinishedPallets', JSON.stringify(updated));
        return updated;
      });
    } else {
      console.log(`Failed to finish pallet at position ${position}`);
    }
  };

  const handleSmartFinish = () => {
    Object.keys(selectedPallets).forEach(position => {
      handleFinishPallet(position);
    });
  };

  const getPositionOptions = (position) => {
    const baseOptions = {
      'Round': ['A grof +', 'A grof', 'A fijn', 'B'],
      'Plum': ['6-Pack', '5-Pack', 'A fijn', 'B']
    };

    if (position.includes('B')) {
      return tomatoType === 'Round' ? ['B'] : ['B'];
    } else if (position.includes('A fijn')) {
      return tomatoType === 'Round' ? ['A fijn'] : ['A fijn'];
    } else if (tomatoType === 'Round') {
      return position.includes('A grof +') ? ['A grof +'] : ['A grof'];
    } else { // Plum
      return position.includes('6 Pack') ? ['6-Pack'] : ['5-Pack'];
    }
  };

  useEffect(() => {
    if (activeMenu === 'statistics') {
      fetchStats();
    }
  }, [activeMenu, timeFrame, locationId]);

  const fetchStats = () => {
    let startDate;
    switch (timeFrame) {
      case 'week':
        startDate = startOfWeek(new Date());
        break;
      case 'month':
        startDate = startOfMonth(new Date());
        break;
      default:
        startDate = startOfDay(new Date());
    }

    axios.get(`/api/pallets/stats/${locationId}`, { params: { startDate: startDate.toISOString() } })
      .then(response => {
        setStats(Array.isArray(response.data) ? response.data : []);
      })
      .catch(error => {
        console.error('Error fetching statistics:', error);
        setStats([]);
      });
  };

  const changeTimeFrame = () => {
    switch (timeFrame) {
      case 'today':
        setTimeFrame('week');
        break;
      case 'week':
        setTimeFrame('month');
        break;
      default:
        setTimeFrame('today');
    }
  };

  const handleScroll = () => {
    const menuButtons = menuRef.current.children;
    const containerWidth = menuRef.current.offsetWidth;
    const scrollPosition = menuRef.current.scrollLeft;

    Array.from(menuButtons).forEach((button, index) => {
      const buttonPosition = button.offsetLeft - scrollPosition;
      const buttonWidth = button.offsetWidth;

      if (buttonPosition < 0 || buttonPosition + buttonWidth > containerWidth) {
        button.style.opacity = "0.5";
      } else {
        button.style.opacity = "1";
      }
    });
  };

  const positions = tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', processStage: 'Light' },
        { label: 'Middle A grof +', processStage: 'Middle' },
        { label: 'Red A grof +', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'A grof Red', processStage: 'Red' },
        { label: 'A grof Middle', processStage: 'Middle' },
        { label: 'A grof Light', processStage: 'Light' },
      ]
    : [
        { label: 'Light 6 Pack', processStage: 'Light' },
        { label: 'Middle 6 Pack', processStage: 'Middle' },
        { label: 'Red 6 Pack', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'Red 5 Pack', processStage: 'Red' },
        { label: 'Middle 5 Pack', processStage: 'Middle' },
        { label: 'Light 5 Pack', processStage: 'Light' },
      ];

      return (
        <Container>
        <ScrollableTopMenu ref={menuRef} onScroll={handleScroll}>
        <MenuButton active={activeMenu === 'selection'} onClick={() => setActiveMenu('selection')}>
          Selection
        </MenuButton>
        <MenuButton active={activeMenu === 'finish'} onClick={() => setActiveMenu('finish')}>
          Finish
        </MenuButton>
        <MenuButton active={activeMenu === 'settings'} onClick={() => setActiveMenu('settings')}>
          Settings
        </MenuButton>
        <MenuButton active={activeMenu === 'statistics'} onClick={() => setActiveMenu('statistics')}>
          Statistics
        </MenuButton>
        <MenuButton active={activeMenu === 'map'} onClick={() => setActiveMenu('map')}>
          Map
        </MenuButton>
      </ScrollableTopMenu>
        
        <ContentArea>
          {activeMenu === 'selection' && (
            <>
              <ToggleButton active={tomatoType === 'Round'} onClick={toggleTomatoType}>
                {tomatoType}
              </ToggleButton>
          <TopGrid>
            {positions.slice(0, 6).map(({ label, processStage }) => (
              <SelectionButton 
                key={label}
                onClick={() => handlePalletSelect(label)}
                selected={!!selectedPallets[label]}
              >
                <ButtonLabel>{label}</ButtonLabel>
                {selectedPallets[label] && (
                  <ButtonInfo>
                    {selectedPallets[label].palletType}<br/>
                    {selectedPallets[label].boxType}
                  </ButtonInfo>
                )}
              </SelectionButton>
            ))}
          </TopGrid>
              <MiddleGrid>
                {positions.slice(6, 8).map(({ label, processStage }) => (
                  <SelectionButton 
                    key={label}
                    onClick={() => handlePalletSelect(label)}
                    selected={!!selectedPallets[label]}
                  >
                    <ButtonLabel>{label}</ButtonLabel>
                    {selectedPallets[label] && (
                      <ButtonInfo>
                        {selectedPallets[label].palletType}<br/>
                        {selectedPallets[label].boxType}
                      </ButtonInfo>
                    )}
                  </SelectionButton>
                ))}
              </MiddleGrid>
              <Divider />
              <Typography>Automaat</Typography>
              <BottomGrid>
                {positions.slice(8).map(({ label, processStage }) => (
                  <SelectionButton 
                    key={label}
                    onClick={() => handlePalletSelect(label)}
                    selected={!!selectedPallets[label]}
                  >
                    <ButtonLabel>{label}</ButtonLabel>
                    {selectedPallets[label] && (
                      <ButtonInfo>
                        {selectedPallets[label].palletType}<br/>
                        {selectedPallets[label].boxType}
                      </ButtonInfo>
                    )}
                  </SelectionButton>
                ))}
              </BottomGrid>
            </>
          )}
    
    {activeMenu === 'finish' && (
          <FinishMenu 
            selectedPallets={selectedPallets}
            onFinishPallet={handleFinishPallet}
            locationId={locationId}
            tomatoType={tomatoType}
            filterRanges={filterRanges}
          />
        )}

        {activeMenu === 'settings' && (
          <Settings 
            locationId={locationId}
            onSettingsUpdate={fetchFilterRanges}
          />
        )}

        {activeMenu === 'statistics' && (
          <StatisticsContainer>
            <StatHeader>
              <StatTitle>{timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}'s Statistics</StatTitle>
              <ChangeButton onClick={changeTimeFrame}>&gt;</ChangeButton>
            </StatHeader>
            <StatGrid>
              {stats.length > 0 ? (
                stats.map((stat, index) => (
                  <StatCard key={index}>
                    <h3>{stat.tomatoType} - {stat.tomatoOption}</h3>
                    <p>Pallet Type: {stat.palletType}</p>
                    <p>Box Type: {stat.boxType}</p>
                    <p>Count: {stat.count}</p>
                  </StatCard>
                ))
              ) : (
                <p>No statistics available for this period.</p>
              )}
            </StatGrid>
          </StatisticsContainer>
        )}

        {activeMenu === 'map' && (
          <Map />
        )}
      </ContentArea>

      {showAddPallet && (
        <ModalOverlay>
          <ModalContent>
            <AddPallet 
              onPalletAdded={handlePalletAdded}
              onCancel={() => setShowAddPallet(false)}
              isMobile={true}
              tomatoType={tomatoType}
              defaultProcessStage={positions.find(p => p.label === currentPosition)?.processStage}
              availableOptions={getPositionOptions(currentPosition)}
              initialData={selectedPallets[currentPosition]}
              skipTomatoOption={true}
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default OperatorBeginningScreen;