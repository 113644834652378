import React, { useEffect, useState, useContext } from 'react';
import axios from '../axiosConfig';
import { UserContext } from '../context/UserContext';
import { format, getISOWeek } from 'date-fns';
import {
  DashboardContainer,
  Sidebar,
  MainContent,
  TitleContainer,
  Title,
  WelcomeMessage,
  TabContainer,
  Tab,
  PalletList,
  PalletItem,
  PalletImage,
  BoxImage,
  ProcessStage,
  InfoContainer,
  InfoText,
  ExportButton
} from '../styles/DashboardStyles';

import {
  palletType1Img,
  palletType2Img,
  palletType3Img,
  palletType4Img,
} from '../assets/pallets';

import {
  boxType1Img,
  boxType2Img,
  boxType3Img,
  boxType4Img,
  boxType5Img,
  boxType6Img,
  boxType7Img,
  boxType8Img,
  boxType9Img,
  boxType10Img,
} from '../assets/boxes';

const getPalletImage = (type) => {
  switch (type) {
    case 'Euro Pallet': return palletType1Img;
    case 'Pool Pallet': return palletType2Img;
    case 'Chep Pallet': return palletType3Img;
    case 'Light Pallet': return palletType4Img;
    default: return null;
  }
};

const getBoxImage = (type) => {
  switch (type) {
    case 'Nexture': return boxType1Img;
    case 'Van Gelder Low': return boxType2Img;
    case 'Van Gelder High': return boxType3Img;
    case 'Black Crate Small': return boxType4Img;
    case 'Green Crate Large': return boxType5Img;
    case 'Black Box': return boxType6Img;
    case 'Royal Red': return boxType7Img;
    case 'Bella Tondo': return boxType8Img;
    case 'Green Crate Small': return boxType9Img;
    case 'Novy': return boxType10Img;
    default: return null;
  }
};

const getProcessStageColor = (stage) => {
  switch (stage) {
    case 'Light': return 'lightgreen';
    case 'Middle': return 'orange';
    case 'Red': return 'red';
    default: return 'grey';
  }
};

const Dashboard = () => {
  const { user } = useContext(UserContext); // Get the user from UserContext
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [pallets, setPallets] = useState([]);
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [tomatoTypes, setTomatoTypes] = useState([]); // Add tomatoTypes state
  const [tomatoOptions, setTomatoOptions] = useState([]); // Add tomatoOptions state

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const currentDay = daysOfWeek[new Date().getDay()];

  const exportToExcel = () => {
    axios.get('/api/pallets/export/excel', { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pallets.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error exporting data:', error));
  };
  
  const exportRoundToExcel = () => {
    axios.get('/api/pallets/export/roundToExcel', { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'round_pallets.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error exporting round data:', error));
  };
  
  const exportPlumToExcel = () => {
    axios.get('/api/pallets/export/plumToExcel', { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'plum_pallets.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error exporting plum data:', error));
  };
  
  const getTomatoTypeName = (typeName) => {
    return typeName ? typeName : 'Unknown Tomato Type';
  };

  const getTomatoOptionName = (optionName) => {
    return optionName ? optionName : 'Unknown Tomato Option';
  };

  useEffect(() => {
    axios.get('/api/locations')
      .then(response => {
        setLocations(response.data);
        const lastLocation = localStorage.getItem('lastLocation');
        if (lastLocation) {
          setSelectedLocation(lastLocation);
        } else if (response.data.length > 0) {
          setSelectedLocation(response.data[0].id);
        }
      })
      .catch(error => {
        console.error('Error fetching locations', error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        }
      });

    // Fetch tomato types
    axios.get('/api/pallets/tomato-types')
      .then(response => {
        console.log('Fetched tomato types:', response.data); // Debug log
        setTomatoTypes(response.data);
      })
      .catch(error => console.error('Error fetching tomato types:', error));
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem('lastLocation', selectedLocation);
      axios.get(`/api/pallets/${selectedLocation}`)
        .then(response => {
          console.log('Fetched pallets:', response.data); // Debug log
          setPallets(response.data);
        })
        .catch(error => {
          console.error('Error fetching pallets', error);
        });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (tomatoTypes.length > 0) {
      const tomatoType = tomatoTypes[0].id; // Example: Set the first tomato type
      axios.get(`/api/pallets/tomato-options/${tomatoType}`)
        .then(response => {
          console.log('Fetched tomato options:', response.data); // Debug log
          setTomatoOptions(response.data);
        })
        .catch(error => console.error('Error fetching tomato options:', error));
    }
  }, [tomatoTypes]);

  const handlePalletAdded = () => {
    if (selectedLocation) {
      axios.get(`/api/pallets/${selectedLocation}`)
        .then(response => {
          setPallets(response.data);
          setShowAddPallet(false);
        })
        .catch(error => {
          console.error('Error fetching pallets', error);
        });
    }
  };

  const handleLocationChange = (locationId) => {
    setSelectedLocation(locationId);
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <PalletList>
          {pallets.length > 0 ? pallets.sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate)).map((pallet) => (
            <PalletItem key={pallet.id}>
              <PalletImage src={getPalletImage(pallet.palletType)} alt={pallet.palletType} />
              <BoxImage src={getBoxImage(pallet.boxType)} alt={pallet.boxType} />
              <ProcessStage color={getProcessStageColor(pallet.processStage)} />
              <InfoContainer>
                <InfoText>Week of the Year: {getISOWeek(new Date(pallet.addedDate))}</InfoText>
                <InfoText>Day of the Week: {daysOfWeek[new Date(pallet.addedDate).getDay()]}</InfoText>
                <InfoText>Pallet Type: {pallet.palletType}</InfoText>
                <InfoText>Box Type: {pallet.boxType}</InfoText>
                <InfoText>Tomato Type: {getTomatoTypeName(pallet.tomatoType)}</InfoText>
                <InfoText>Tomato Option: {getTomatoOptionName(pallet.tomatoOption)}</InfoText>
                <InfoText>Process Stage: {pallet.processStage}</InfoText>
              </InfoContainer>
            </PalletItem>
          )) : <p>No pallets available.</p>}
        </PalletList>
      </Sidebar>
      <MainContent>
        <TitleContainer>
          <Title>Dashboard</Title>
          <WelcomeMessage>Welcome {user?.name || 'User'}, have a nice {currentDay}!</WelcomeMessage>
        </TitleContainer>
        <TabContainer>
          {locations.map((location) => (
            <Tab
              key={location.id}
              active={location.id === selectedLocation}
              onClick={() => handleLocationChange(location.id)}
            >
              {location.name}
            </Tab>
          ))}
        </TabContainer>
        <ExportButton onClick={exportToExcel}>
          Export to Excel
        </ExportButton>
        <ExportButton onClick={exportRoundToExcel}>
          Export Round Pallets to Excel
        </ExportButton>
        <ExportButton onClick={exportPlumToExcel}>
          Export Plum Pallets to Excel
        </ExportButton>
      </MainContent>
    </DashboardContainer>
  );
};  

export default Dashboard;