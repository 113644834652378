import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../axiosConfig';
import { format } from 'date-fns';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  background-color: ${props => props.active ? 'white' : props.theme.colors.primary};
  color: ${props => props.active ? props.theme.colors.primary : 'white'};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: 10px 15px;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  font-size: 1em;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 15px;
  width: 100%;
`;

const TopGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
`;

const MiddleGrid = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
`;

const BottomGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${props => props.selected ? props.theme.colors.primary : 'white'};
  color: ${props => props.selected ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
  cursor: ${props => props.selected ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  opacity: ${props => props.selected ? 1 : 0.5};
  &:hover {
    opacity: ${props => props.selected ? 0.8 : 0.5};
  }
`;

const Divider = styled.hr`
  margin: 20px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #eee;
`;

const Typography = styled.h2`
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const FeedContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
`;

const FeedItem = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FeedItemInfo = styled.div`
  flex-grow: 1;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const FinishMenu = ({ selectedPallets, onFinishPallet, locationId, tomatoType, filterRanges }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [feedPallets, setFeedPallets] = useState([]);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('Finished');

  useEffect(() => {
    if (showFeed) {
      fetchTodaysPallets();
    }
  }, [showFeed]);

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'Finished' ? 'Unfinished' : 'Finished');
  };

  const fetchTodaysPallets = () => {
    setError(null);
    console.log('Fetching pallets for locationId:', locationId); // Add this log
    axios.get(`/api/pallets/${locationId}`)
      .then(response => {
        const today = new Date().toISOString().split('T')[0];
        const todaysPallets = response.data.filter(pallet => 
          pallet.addedDate.startsWith(today)
        ).sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
        setFeedPallets(todaysPallets);
      })
      .catch(error => {
        console.error('Error fetching pallets:', error);
        console.error('Error details:', error.response); // Add this log
        setError(`Failed to fetch today's pallets. Error: ${error.message}`);
      });
  };

  const handleFinishPallet = (position) => {
    if (isSubmitting || !selectedPallets[position]) return;

    setIsSubmitting(true);

    const palletData = selectedPallets[position];
    const positionInfo = positions.find(p => p.label === position);
    const processStage = positionInfo ? positionInfo.processStage.toLowerCase() : 'unknown';

    let data = {
      ...palletData,
      addedDate: new Date().toISOString(),
      locationId,
      position,
      processStage,
      isUnfinished: mode === 'Unfinished',
    };

    // Safely add filter ranges if they exist
    if (filterRanges && filterRanges[processStage]) {
      data.filterRangeMin = filterRanges[processStage].min;
      data.filterRangeMax = filterRanges[processStage].max;
    } else {
      console.warn(`Filter ranges not found for process stage: ${processStage}`);
    }

    if (mode === 'Unfinished') {
      const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
      const layers = prompt(`How many full layers of tomato boxes? (Max: ${maxLayers})`);
      if (layers === null || layers === "" || isNaN(layers) || parseInt(layers) > maxLayers) {
        alert("Invalid number of layers");
        setIsSubmitting(false);
        return;
      }
      
      const additionalBoxes = prompt('How many additional boxes?');
      if (additionalBoxes === null || additionalBoxes === "" || isNaN(additionalBoxes)) {
        alert("Invalid number of additional boxes");
        setIsSubmitting(false);
        return;
      }

      const totalBoxes = calculateTotalBoxes(palletData.palletType, palletData.boxType, parseInt(layers), parseInt(additionalBoxes));

      data = {
        ...data,
        layers: parseInt(layers),
        additionalBoxes: parseInt(additionalBoxes),
        totalBoxes
      };
    } else {
      const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
      const totalBoxes = calculateTotalBoxes(palletData.palletType, palletData.boxType, maxLayers);

      data = {
        ...data,
        layers: maxLayers,
        additionalBoxes: 0,
        totalBoxes
      };
    }

    axios.post('/api/pallets/add', data)
      .then(response => {
        onFinishPallet(position, true);
        alert('Pallet successfully added to database');
        if (showFeed) {
          fetchTodaysPallets();
        }
      })
      .catch(error => {
        console.error('Error adding pallet:', error);
        onFinishPallet(position, false);
        alert('Error adding pallet to database: ' + (error.response?.data || error.message));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  
  const getBoxesPerLayer = (palletType, boxType) => {
    switch (palletType) {
      case 'Euro Pallet':
        return 8; // Always 8 boxes per layer, 15 layers total
      case 'Chep Pallet':
        if (boxType === 'Black Crate Small' || boxType === 'Black Box') {
          return 10; // 10 boxes per layer, 11 or 12 layers depending on box type
        }
        break;
      case 'Pool Pallet':
        if (boxType === 'Van Gelder High' || boxType === 'Van Gelder Low' || boxType === 'Green Crate Large') {
          return 5; // 5 boxes per layer, different total layers
        }
        break;
      case 'Light Pallet':
        return 10; // 10 boxes per layer, 10 layers total
    }
    throw new Error(`Unknown combination of pallet type "${palletType}" and box type "${boxType}"`);
  };
  
  const getMaxLayers = (palletType, boxType) => {
    switch (palletType) {
      case 'Euro Pallet':
        return 15;
      case 'Chep Pallet':
        if (boxType === 'Black Crate Small') {
          return 11;
        } else if (boxType === 'Black Box') {
          return 12;
        }
        break;
      case 'Pool Pallet':
        if (boxType === 'Van Gelder High') {
          return 13;
        } else if (boxType === 'Van Gelder Low') {
          return 19;
        } else if (boxType === 'Green Crate Large') {
          return 14;
        }
        break;
      case 'Light Pallet':
        return 10;
    }
    throw new Error(`Unknown combination of pallet type "${palletType}" and box type "${boxType}"`);
  };
  
  const calculateTotalBoxes = (palletType, boxType, layers, additionalBoxes = 0) => {
    const boxesPerLayer = getBoxesPerLayer(palletType, boxType);
    return (boxesPerLayer * layers) + additionalBoxes;
  };

  const handleDeletePallet = (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      axios.delete(`/api/pallets/remove/${palletId}`)
        .then(() => {
          alert('Pallet successfully deleted');
          fetchTodaysPallets(); // Refresh the list after deletion
        })
        .catch(error => {
          console.error('Error deleting pallet:', error);
          alert('Error deleting pallet: ' + (error.response?.data?.error || error.message));
        });
    }
  };

  const positions = tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', processStage: 'Light' },
        { label: 'Middle A grof +', processStage: 'Middle' },
        { label: 'Red A grof +', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'A grof Red', processStage: 'Red' },
        { label: 'A grof Middle', processStage: 'Middle' },
        { label: 'A grof Light', processStage: 'Light' },
      ]
    : [
        { label: 'Light 6 Pack', processStage: 'Light' },
        { label: 'Middle 6 Pack', processStage: 'Middle' },
        { label: 'Red 6 Pack', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'Red 5 Pack', processStage: 'Red' },
        { label: 'Middle 5 Pack', processStage: 'Middle' },
        { label: 'Light 5 Pack', processStage: 'Light' },
      ];

      return (
        <Container>
          <ButtonContainer>
            <StyledButton onClick={() => setShowFeed(true)}>
              Show Feed
            </StyledButton>
            <StyledButton 
              active={mode === 'Unfinished'}
              onClick={toggleMode}
            >
              {mode}
            </StyledButton>
          </ButtonContainer>
          <TopGrid>
            {positions.slice(0, 6).map(({ label }) => (
              <Button 
                key={label}
                onClick={() => handleFinishPallet(label)}
                selected={!!selectedPallets[label]}
              >
                {label}
                {selectedPallets[label] && (
                  <>
                    <br />
                    {selectedPallets[label].palletType}
                    <br />
                    {selectedPallets[label].boxType}
                  </>
                )}
              </Button>
            ))}
          </TopGrid>
          <MiddleGrid>
            {positions.slice(6, 8).map(({ label }) => (
              <Button 
                key={label}
                onClick={() => handleFinishPallet(label)}
                selected={!!selectedPallets[label]}
              >
                {label}
                {selectedPallets[label] && (
                  <>
                    <br />
                    {selectedPallets[label].palletType}
                    <br />
                    {selectedPallets[label].boxType}
                  </>
                )}
              </Button>
            ))}
          </MiddleGrid>
          <Divider />
          <Typography>Automaat</Typography>
          <BottomGrid>
            {positions.slice(8).map(({ label }) => (
              <Button 
                key={label}
                onClick={() => handleFinishPallet(label)}
                selected={!!selectedPallets[label]}
              >
                {label}
                {selectedPallets[label] && (
                  <>
                    <br />
                    {selectedPallets[label].palletType}
                    <br />
                    {selectedPallets[label].boxType}
                  </>
                )}
              </Button>
            ))}
          </BottomGrid>
          {showFeed && (
        <Overlay onClick={() => setShowFeed(false)}>
          <FeedContainer onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setShowFeed(false)}>&times;</CloseButton>
            <h3>Today's Pallets</h3>
            {error ? (
              <ErrorMessage>{error}</ErrorMessage>
            ) : feedPallets.length > 0 ? (
              feedPallets.map((pallet) => (
                <FeedItem key={pallet.id}>
                  <FeedItemInfo>
                    <div>Position: {pallet.position}</div>
                    <div>Tomato Type: {pallet.tomatoType}</div>
                    <div>Tomato Option: {pallet.tomatoOption}</div>
                    <div>Process Stage: {pallet.processStage}</div>
                    <div>Pallet Type: {pallet.palletType}</div>
                    <div>Box Type: {pallet.boxType}</div>
                    <div>Added: {format(new Date(pallet.addedDate), 'HH:mm:ss')}</div>
                    <div>User: {pallet.userName || 'Unknown'}</div>
                  </FeedItemInfo>
                  <DeleteButton onClick={() => handleDeletePallet(pallet.id)}>
                    Delete
                  </DeleteButton>
                </FeedItem>
              ))
            ) : (
              <p>No pallets have been added today.</p>
            )}
          </FeedContainer>
        </Overlay>
      )}
    </Container>
  );
};

export default FinishMenu;