import styled from 'styled-components';

export const AddPalletContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Step = styled.div`
  margin-bottom: 20px;
`;

export const ImageButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:hover {
    opacity: 0.8;
  }
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  &:hover {
    opacity: 0.8;
  }
`;

export const BoxTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: ${(props) => (props.isMobile ? '400px' : 'auto')};
  overflow-y: ${(props) => (props.isMobile ? 'scroll' : 'visible')};
`;

export const TomatoOptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: ${(props) => (props.isMobile ? '400px' : 'auto')};
  overflow-y: ${(props) => (props.isMobile ? 'scroll' : 'visible')};
`;
