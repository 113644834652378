import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import { theme } from './theme';
import LoginDesktop from './components/LoginDesktop';
import LoginMobile from './components/LoginMobile';
import Dashboard from './components/Dashboard';
import Operator from './components/Operator';

const App = () => {
  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  const getLastLocation = () => {
    return localStorage.getItem('lastLocation') || '/dashboard';
  };

  useEffect(() => {
    // Clear lastLocation when the app loads
    localStorage.removeItem('lastLocation');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/login" render={(props) => (
            isMobile ? <LoginMobile {...props} /> : <LoginDesktop {...props} />
          )} />
          <Route path="/dashboard" render={(props) => (
            isAuthenticated() ? (
              isMobile ? <Redirect to="/operator" /> : <Dashboard {...props} />
            ) : <Redirect to="/login" />
          )} />
          <Route path="/operator" render={(props) => (
            isAuthenticated() ? (
              isMobile ? <Operator {...props} /> : <Redirect to="/dashboard" />
            ) : <Redirect to="/login" />
          )} />
          <Route exact path="/" render={() => {
            if (isAuthenticated()) {
              const lastLocation = getLastLocation();
              localStorage.setItem('lastLocation', lastLocation);
              return <Redirect to="/login" />;
            } else {
              return <Redirect to="/login" />;
            }
          }} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;






