import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
`;

export const Sidebar = styled.div`
  width: 30%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  text-align: left;
  margin: 0;
  padding: 0;
`;

export const WelcomeMessage = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-top: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
`;

export const Tab = styled.button`
  background-color: ${(props) => (props.active ? props.theme.colors.primary : '#333')};
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const PalletList = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
`;

export const PalletItem = styled.div`
  background-color: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PalletImage = styled.img`
  width: 200px;
  height: 150px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const BoxImage = styled.img`
  width: 200px;
  height: 150px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ProcessStage = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const InfoText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #333;
`;

export const AddPalletButton = styled.button`
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ExportButton = styled.button`
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
