export const theme = {
    colors: {
      background: '#F7F7F7', // Milky sweet white
      text: '#333', // Dark grey for text
      primary: '#4CAF50', // High hue green
      secondary: '#FF6347', // High hue red
      accent: '#61dafb', // Light blue
    },
    fonts: {
      primary: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
  };
  