import React, { useState } from 'react';
import axios from '../axiosConfig';
import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoginButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.red {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
`;

const LoginMobile = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    axios.post('/api/users/login', { username, password })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        window.location.href = '/dashboard';
      })
      .catch(error => {
        alert('Login failed: ' + error.response.data.message);
      });
  };

  return (
    <LoginContainer>
      <h1>Login (Mobile)</h1>
      <Input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Username" 
      />
      <Input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password" 
      />
      <LoginButton onClick={handleLogin}>Login</LoginButton>
    </LoginContainer>
  );
};

export default LoginMobile;
