import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import {
  AddPalletContainer,
  Step,
  ImageButton,
  Image,
  Button,
  BoxTypeContainer,
  TomatoOptionContainer
} from '../styles/AddPalletStyles';

// Import images for pallet types
import {
  palletType1Img,
  palletType2Img,
  palletType3Img,
  palletType4Img,
} from '../assets/pallets';

// Import images for box types
import {
  boxType1Img,
  boxType2Img,
  boxType3Img,
  boxType4Img,
  boxType5Img,
  boxType6Img,
  boxType7Img,
  boxType8Img,
  boxType9Img,
  boxType10Img,
} from '../assets/boxes';

// Import images for tomato types and options
import {
  plumImg,
  roundImg,
  plum5PackGreenImg,
  plum5PackOrangeImg,
  plum5PackRedImg,
  plum6PackGreenImg,
  plum6PackOrangeImg,
  plum6PackRedImg,
  plumAFijnGreenImg,
  plumAFijnOrangeImg,
  plumAFijnRedImg,
  plumBGreenImg,
  plumBOrangeImg,
  plumBRedImg,
  roundAFijnGreenImg,
  roundAFijnOrangeImg,
  roundAFijnRedImg,
  roundAGrofGreenImg,
  roundAGrofOrangeImg,
  roundAGrofRedImg,
  roundAGrofPlusGreenImg,
  roundAGrofPlusOrangeImg,
  roundAGrofPlusRedImg,
  roundBGreenImg,
  roundBOrangeImg,
  roundBRedImg,
} from '../assets/tomatoes';

const AddPallet = ({ onPalletAdded, onCancel, isMobile, tomatoType, defaultProcessStage, availableOptions, initialData, skipTomatoOption, locationId }) => {
  const [step, setStep] = useState(1);
  const [palletType, setPalletType] = useState(initialData?.palletType || null);
  const [boxType, setBoxType] = useState(initialData?.boxType || null);
  const [processStage, setProcessStage] = useState(initialData?.processStage || defaultProcessStage);
  const [selectedTomatoOption, setSelectedTomatoOption] = useState(initialData?.tomatoOption || null);

  useEffect(() => {
    if (initialData) {
      setPalletType(initialData.palletType);
      setBoxType(initialData.boxType);
      setProcessStage(initialData.processStage);
      setSelectedTomatoOption(initialData.tomatoOption);
    }
  }, [initialData]);

  const handleNextStep = () => setStep(step + 1);
  const handlePreviousStep = () => setStep(step - 1);

  const handleSubmit = () => {
    const palletData = {
      palletType,
      boxType,
      processStage,
      tomatoType,
      tomatoOption: skipTomatoOption ? availableOptions[0] : selectedTomatoOption,
    };
  
    axios.get(`/api/pallets/recent/${locationId}`)
      .then(response => {
        if (response.data.recentlyAdded) {
          if (window.confirm(`This pallet was recently added by ${response.data.user}. Are you sure you want to add the pallet?`)) {
            onPalletAdded(palletData);
          }
        } else {
          onPalletAdded(palletData);
        }
      })
      .catch(error => {
        console.error('Error checking recent pallets:', error);
        alert('Error checking recent pallets. Please try again.');
      });
  };

  const palletTypes = [
    { type: 'Euro Pallet', img: palletType1Img },
    { type: 'Pool Pallet', img: palletType2Img },
    { type: 'Chep Pallet', img: palletType3Img },
    { type: 'Light Pallet', img: palletType4Img },
  ];

  const boxTypes = [
    { type: 'Nexture', img: boxType1Img },
    { type: 'Van Gelder Low', img: boxType2Img },
    { type: 'Van Gelder High', img: boxType3Img },
    { type: 'Black Crate Small', img: boxType4Img },
    { type: 'Green Crate Large', img: boxType5Img },
    { type: 'Black Box', img: boxType6Img },
    { type: 'Royal Red', img: boxType7Img },
    { type: 'Bella Tondo', img: boxType8Img },
    { type: 'Green Crate Small', img: boxType9Img },
    { type: 'Novy', img: boxType10Img },
  ];

  const tomatoOptions = {
    'Round': {
      'A grof +': { img: roundAGrofPlusGreenImg },
      'A grof': { img: roundAGrofGreenImg },
      'A fijn': { img: roundAFijnGreenImg },
      'B': { img: roundBGreenImg },
    },
    'Plum': {
      '6-Pack': { img: plum6PackGreenImg },
      '5-Pack': { img: plum5PackGreenImg },
      'A fijn': { img: plumAFijnGreenImg },
      'B': { img: plumBGreenImg },
    },
  };

  return (
    <AddPalletContainer>
      {step === 1 && (
        <Step>
          <h2>Select Pallet Type</h2>
          {palletTypes.map(({ type, img }) => (
            <ImageButton key={type} onClick={() => { setPalletType(type); handleNextStep(); }}>
              <Image src={img} alt={type} />
            </ImageButton>
          ))}
        </Step>
      )}
      {step === 2 && (
        <Step>
          <h2>Select Box Type</h2>
          <BoxTypeContainer isMobile={isMobile}>
            {boxTypes.map(({ type, img }) => (
              <ImageButton key={type} onClick={() => { setBoxType(type); handleNextStep(); }}>
                <Image src={img} alt={type} />
              </ImageButton>
            ))}
          </BoxTypeContainer>
          <Button onClick={handlePreviousStep}>Back</Button>
        </Step>
      )}
      {!skipTomatoOption && step === 3 && (
        <Step>
          <h2>Select Tomato Option</h2>
          <BoxTypeContainer isMobile={isMobile}>
            {availableOptions.map((option) => (
              <ImageButton key={option} onClick={() => { setSelectedTomatoOption(option); handleNextStep(); }}>
                <Image src={tomatoOptions[tomatoType][option].img} alt={option} />
                <p>{option}</p>
              </ImageButton>
            ))}
          </BoxTypeContainer>
          <Button onClick={handlePreviousStep}>Back</Button>
        </Step>
      )}
      {step === (skipTomatoOption ? 3 : 4) && (
        <Step>
          <h2>Confirm Selection</h2>
          <p>Pallet Type: {palletType}</p>
          <p>Box Type: {boxType}</p>
          <p>Tomato Type: {tomatoType}</p>
          {!skipTomatoOption && <p>Tomato Option: {selectedTomatoOption}</p>}
          <p>Process Stage: {processStage}</p>
          <Button onClick={handleSubmit}>Confirm</Button>
          <Button onClick={handlePreviousStep}>Back</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Step>
      )}
    </AddPalletContainer>
  );
};

export default AddPallet;