import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../axiosConfig';

const Container = styled.div`
  padding: 20px;
`;

const Input = styled.input`
  padding: 5px;
  margin: 5px 0;
  width: 100%;
`;

const RangeInput = styled(Input)`
  width: 60px;
  margin-right: 5px;
`;

const RangeLabel = styled.label`
  display: inline-block;
  width: 100px;
  margin-right: 10px;
`;

const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  &:hover {
    opacity: 0.8;
  }
`;

const Settings = ({ locationId, onSettingsUpdate }) => {
  const [manualPercentages, setManualPercentages] = useState({ red: 33, middle: 33, light: 34 });
  const [mapMode, setMapMode] = useState('automatic');
  const [filterRanges, setFilterRanges] = useState({
    red: { min: 0.04, max: 0.14 },
    middle: { min: 0.14, max: 0.76 },
    light: { min: 0.76, max: 0.96 }
  });

  useEffect(() => {
    const storedPercentages = localStorage.getItem('manualPercentages');
    if (storedPercentages) {
      setManualPercentages(JSON.parse(storedPercentages));
    }

    const storedRanges = localStorage.getItem('filterRanges');
    if (storedRanges) {
      setFilterRanges(JSON.parse(storedRanges));
    }
  }, []);

  const handlePercentageChange = (stage, value) => {
    const newValue = parseInt(value) || 0;
    setManualPercentages(prev => {
      const updated = { ...prev, [stage]: newValue };
      localStorage.setItem('manualPercentages', JSON.stringify(updated));
      return updated;
    });
  };

  const handleFilterRangeChange = (stage, minOrMax, value) => {
    const newValue = parseFloat(value) || 0;
    setFilterRanges(prev => {
      let updated = { ...prev };
      updated[stage][minOrMax] = newValue;

      // Adjust other ranges based on the changed value
      if (stage === 'red' && minOrMax === 'max') {
        updated.middle.min = newValue;
      } else if (stage === 'light' && minOrMax === 'min') {
        updated.middle.max = newValue;
      }

      localStorage.setItem('filterRanges', JSON.stringify(updated));
      return updated;
    });
  };

  const saveSettings = () => {
    console.log('Attempting to save settings...');
    console.log('Endpoint:', `/api/settings/${locationId}`);
    console.log('Data:', { manualPercentages, filterRanges });
  
    axios.post(`/api/settings/${locationId}`, {
      manualPercentages,
      filterRanges
    })
      .then(response => {
        console.log('Settings saved successfully:', response.data);
        alert('Settings saved successfully');
        if (onSettingsUpdate) {
          onSettingsUpdate();
        }
      })
      .catch(error => {
        console.error('Error saving settings:', error);
        console.error('Error response:', error.response);
        alert('Error saving settings: ' + (error.response?.data || error.message));
      });
  };
  
  return (
    <Container>
      <h2>Settings</h2>
      <h3>Manual Percentages</h3>
      <Input 
        type="number" 
        value={manualPercentages.red} 
        onChange={(e) => handlePercentageChange('red', e.target.value)}
        placeholder="Red %"
      />
      <Input 
        type="number" 
        value={manualPercentages.middle} 
        onChange={(e) => handlePercentageChange('middle', e.target.value)}
        placeholder="Middle %"
      />
      <Input 
        type="number" 
        value={manualPercentages.light} 
        onChange={(e) => handlePercentageChange('light', e.target.value)}
        placeholder="Light %"
      />

      <h3>Filter Ranges</h3>
      <RangeLabel>Red:</RangeLabel>
      <RangeInput 
        type="number" 
        value={filterRanges.red.min} 
        onChange={(e) => handleFilterRangeChange('red', 'min', e.target.value)}
        step="0.01"
        min="0.04"
        max="0.14"
      />
      <RangeInput 
        type="number" 
        value={filterRanges.red.max} 
        onChange={(e) => handleFilterRangeChange('red', 'max', e.target.value)}
        step="0.01"
        min="0.04"
        max="0.14"
      />
      <RangeLabel>Middle:</RangeLabel>
      <RangeInput 
        type="number" 
        value={filterRanges.middle.min} 
        onChange={(e) => handleFilterRangeChange('middle', 'min', e.target.value)}
        step="0.01"
        min="0.14"
        max="0.76"
      />
      <RangeInput 
        type="number" 
        value={filterRanges.middle.max} 
        onChange={(e) => handleFilterRangeChange('middle', 'max', e.target.value)}
        step="0.01"
        min="0.14"
        max="0.76"
      />
      <RangeLabel>Light:</RangeLabel>
      <RangeInput 
        type="number" 
        value={filterRanges.light.min} 
        onChange={(e) => handleFilterRangeChange('light', 'min', e.target.value)}
        step="0.01"
        min="0.76"
        max="0.96"
      />
      <RangeInput 
        type="number" 
        value={filterRanges.light.max} 
        onChange={(e) => handleFilterRangeChange('light', 'max', e.target.value)}
        step="0.01"
        min="0.76"
        max="0.96"
      />
      <h3>Map Settings</h3>
        <select value={mapMode} onChange={(e) => setMapMode(e.target.value)}>
        <option value="automatic">Automatic</option>
        <option value="semi-automatic">Semi-Automatic</option>
        <option value="manual">Manual</option>
      </select>
      <Button onClick={saveSettings}>Save Settings</Button>
    </Container>
  );
};

export default Settings;