import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../axiosConfig';

const Container = styled.div`
  padding: 20px;
`;

const MapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: 5px;
  margin-bottom: 20px;
`;

const Cell = styled.div`
  background-color: ${props => props.color};
  border: 1px solid ${props => props.theme.colors.primary};
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
`;

const Map = ({ locationId, filterRanges }) => {
  const [mapData, setMapData] = useState({});

  useEffect(() => {
    fetchMapData();
  }, [locationId]);

  const fetchMapData = () => {
    axios.get(`/api/map/${locationId}`)
      .then(response => {
        setMapData(response.data);
      })
      .catch(error => {
        console.error('Error fetching map data:', error);
      });
  };

  const getColor = (value) => {
    if (value === null) return 'white';
    
    const lightMin = filterRanges.light.min;
    const middleMin = filterRanges.middle.min;
    const redMin = filterRanges.red.min;
    const redMax = filterRanges.red.max;

    if (value >= lightMin) return `rgb(144, 238, 144)`;  // Light green
    if (value >= middleMin) return `rgb(255, 165, 0)`;   // Orange
    if (value >= redMin) return `rgb(255, 0, 0)`;        // Red

    // Gradient for values below redMin
    const gradientPercent = (value - redMin) / (redMax - redMin);
    const r = Math.floor(255 * gradientPercent);
    return `rgb(${r}, 0, 0)`;
  };

  const renderMap = (section, rows, columns) => (
    <div key={section}>
      <SectionTitle>{section}</SectionTitle>
      <MapGrid columns={columns}>
        {Array.from({ length: rows * columns }).map((_, index) => {
          const cellData = mapData[section] && mapData[section][index];
          const color = cellData ? getColor(cellData.value) : 'white';
          return (
            <Cell 
              key={index}
              color={color}
              onClick={() => handleCellClick(section, index, cellData)}
            >
              {index + 1}
            </Cell>
          );
        })}
      </MapGrid>
    </div>
  );

  const handleCellClick = (section, index, cellData) => {
    if (cellData) {
      alert(`Section: ${section}\nPosition: ${index + 1}\nPallet Type: ${cellData.palletType}\nBox Type: ${cellData.boxType}\nTomato Type: ${cellData.tomatoType}\nTomato Option: ${cellData.tomatoOption}\nProcess Stage: ${cellData.processStage}`);
    } else {
      alert(`Section: ${section}\nPosition: ${index + 1}\nNo pallet data available`);
    }
  };

  return (
    <Container>
      <h2>Storage Map</h2>
      {renderMap('Greenseas', 1, 2)}
      {renderMap('Bakker', 6, 5)}
      {renderMap('Bakker Extra', 6, 2)}
      {renderMap('Plum misc', 2, 2)}
      {renderMap('Plum misc Extra', 2, 3)}
      {renderMap('Plum B', 3, 6)}
      {renderMap('Plum 6pack', 1, 3)}
      {renderMap('Plum 6pack Extra', 1, 4)}
      {renderMap('Plum 6pack Large', 3, 7)}
      {renderMap('A grof midden', 4, 8)}
      {renderMap('A grof+ wand', 4, 3)}
      {renderMap('A grof+ wand Extra', 3, 4)}
      {renderMap('A grof+ open', 4, 3)}
      {renderMap('B', 8, 7)}
      {renderMap('A grof rood', 6, 5)}
      {renderMap('A grof licht', 7, 6)}
      {renderMap('A grof licht Extra', 4, 7)}
      {renderMap('Plum 5pack', 4, 8)}
      {renderMap('Plum 5pack Extra', 2, 6)}
      {renderMap('Plum 5pack Small', 2, 4)}
      {renderMap('V.Gelder', 2, 6)}
    </Container>
  );
};

export default Map;